import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useParams, useNavigate, useOutletContext } from "react-router-dom";
import { TextField } from "@mui/material";
import LabelView from "../../atoms/LabelView/LabelView";
import ButtonView from "../../atoms/ButtonView/ButtonView";
import { resetPassword } from "../../../features/users/usersSlice";
import { validatePassword } from "../../../utils/ProgUtils";
import { muiColors, redColor } from "../../../utils/globalStyles";

const userDetailFields = [
  { id: "password", title: "Password" },
  { id: "confirmPassword", title: "Confirm Password" },
];

export default function ResetPasswordView(props) {
  const { isMobile } = useOutletContext();
  const [userDetails, setUserDetails] = useState({
    password: "",
    confirmPassword: "",
  });
  const { resetPasswordToken } = useParams();

  const [errorMessage, setErrorMessage] = useState("");
  const [isSending, setIsSending] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const userDetailInput = ({ title, id }) => {
    const dynamicInput = {
      ...styles.input,
      width: isMobile ? "90%" : 400,
    };

    return (
      <div key={id}>
        <TextField
          label={title}
          variant="outlined"
          required={id !== "lastName"}
          type={"password"}
          sx={dynamicInput}
          value={userDetails[id]}
          onChange={(e) =>
            setUserDetails({ ...userDetails, [id]: e.target.value })
          }
        />
      </div>
    );
  };

  const handleSubmit = async () => {
    const { password, confirmPassword } = userDetails;

    // Let BE handle email/password validation
    const validResp = validatePassword(password, confirmPassword, "");

    if (validResp.isValid) {
      setIsSending(true);
      try {
        const resp = await dispatch(
          resetPassword({ password, confirmPassword, resetPasswordToken })
        );
        setErrorMessage("");
        alert(
          "Your password has been successfully reset! Please log in with your new password."
        );
        navigate("/login");
      } catch (err) {
        console.log("RESET PASSWORD ERROR", err);
        setErrorMessage(err.message);
      }
      setIsSending(false);
    } else {
      setErrorMessage(validResp.errorMessage);
    }
  };

  // if any field is falsey, form is incomplete
  const isFormComplete = !Object.values(userDetails).some((detail) => !detail);

  return (
    <div style={styles.container}>
      <LabelView text="Enter your new password" type="pageHeader" />
      <div style={styles.inputsContainer}>
        {userDetailFields
          .filter((field) => field.id !== "isHostRequested")
          .map((field) => userDetailInput(field))}
      </div>
      {!!errorMessage && (
        <LabelView
          text={errorMessage}
          style={{ color: redColor, marginBottom: 8 }}
        />
      )}
      <div style={{ display: "flex", marginTop: 12 }}>
        <ButtonView
          text="Submit"
          color={muiColors.success}
          variant="contained"
          disabled={!isFormComplete || isSending}
          onClick={handleSubmit}
        />
        <ButtonView
          text="Back"
          sx={{ ml: 2 }}
          color={muiColors.black}
          variant="contained"
          to="/"
          disabled={isSending}
        />
      </div>
      {isSending && <div>Sending...</div>}
    </div>
  );
}

const styles = {
  container: {
    padding: 16,
  },
  inputsContainer: {
    display: "flex",
    flexDirection: "column",
  },
  input: {
    m: 1,
  },
};
