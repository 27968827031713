import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import LabelView from "../../atoms/LabelView/LabelView";
import ButtonView from "../../atoms/ButtonView/ButtonView";
import {
  grayColor1,
  paletteRed,
  grayColor2,
  grayColor3,
} from "../../../utils/globalStyles";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import PeopleIcon from "@mui/icons-material/People";
import FestivalIcon from "@mui/icons-material/Festival";

export default function MobileNavView() {
  const [selectedButton, setSelectedButton] = useState("events");
  const navigate = useNavigate();

  const buttonMap = [
    {
      id: "events",
      path: "/events",
      text: "This Week",
      icon: CalendarMonthIcon,
    },
    {
      id: "hosts",
      path: "/hostsOverview",
      text: "Community",
      icon: PeopleIcon,
    },
    {
      id: "workshops",
      path: "/specialEvents",
      text: "Workshops",
      icon: FestivalIcon,
    },
  ];

  const BottomButton = (props) => {
    const { id, path, text, icon: Icon } = props.details;

    const onClick = () => {
      setSelectedButton(id);
      navigate(path);
    };

    return (
      <div onClick={onClick} style={styles.buttonContainer}>
        <ButtonView
          startIcon={
            <Icon style={{ fontSize: selectedButton === id ? 24 : 32 }} />
          }
          sx={{
            padding: 0,
            ml: "8px", // offsets margin in startIcon
            color: selectedButton === id ? grayColor1 : grayColor3,
          }}
        />
        {selectedButton === id && (
          <LabelView
            text={text}
            type="permanentMarkerSmall"
            style={{ color: grayColor2 }}
          />
        )}
      </div>
    );
  };

  return (
    <div style={styles.container}>
      {buttonMap.map((details) => (
        <BottomButton key={details.id} details={details} />
      ))}
    </div>
  );
}

const styles = {
  container: {
    height: 60,
    width: "100%",
    position: "fixed",
    bottom: 0,
    display: "flex",
    justifyContent: "space-around",
    alignItems: "center",
    background: paletteRed,
    zIndex: 10,
  },
  buttonContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    paddingTop: 4,
    width: "33.33%",
  },
};
