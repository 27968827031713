import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import Api from "../../utils/Api";
import { convertToFormDataUser } from "../../utils/ProgUtils";

// NOTES

// .unwrap() is a helper method that simply takes the promise returned by createAsyncThunk and either:
//    Resolves the returned payload if the thunk was fulfilled.
//    Throws the error payload if the thunk was rejected.

// Use this in the component as the thunk might want access to resp metadata.
// Unwrapping gives me better control of resp.data specifically
// https://chatgpt.com/c/66eab5d5-5390-8007-8887-87e3b1d76e4b

// THUNKS

export const attemptSignup = createAsyncThunk(
  "users/attemptSignup",
  async (user, { rejectWithValue }) => {
    // maybe change user to userDetails later
    // const name = `${newUser.firstName} ${newUser.lastName}`;
    // const encryptedPassword = newUser.password; // return to this
    // const params = { ...newUser, name, encryptedPassword };
    try {
      const resp = await Api.post(
        "users/",
        { user },
        { withCredentials: true }
      );
      console.log("CREATE NEW USER SUCCESS", resp);
      return resp.data;
    } catch (err) {
      console.log("CREATE NEW USER ERROR", err);
      return rejectWithValue(err.response.data); // adds payload and provides error data
    }
  }
);

export const sendPasswordResetEmail = createAsyncThunk(
  "users/sendPasswordResetEmail",
  async (email, { rejectWithValue }) => {
    try {
      const resp = await Api.post(
        "users/request_reset_password_email",
        { email },
        { withCredentials: true } // cookies/session
      );
      console.log("SEND PASSWORD RESET EMAIL SUCCESS", resp);
      return resp.data;
    } catch (err) {
      console.log("SEND PASSWORD RESET EMAIL ERROR", err);
      return rejectWithValue(err.response.data); // adds payload and provides error data
    }
  }
);

export const resetPassword = createAsyncThunk(
  "users/resetPassword",
  async (
    { password, confirmPassword, resetPasswordToken },
    { rejectWithValue }
  ) => {
    try {
      const resp = await Api.post(
        "users/reset_password",
        { password, confirmPassword, resetPasswordToken },
        { withCredentials: true }
      );
      console.log("RESET PASSWORD ACTION SUCCESS", resp);
      return resp.data;
    } catch (err) {
      console.log("RESET PASSWORD ACTION ERROR", err);
      return rejectWithValue(err.response.data); // adds payload and provides error data
    }
  }
);

export const attemptLogin = createAsyncThunk(
  "users/attemptLogin",
  async (user, { rejectWithValue }) => {
    // const encryptedPassword = loginDetails.password; // return to this
    // const params = { email: loginDetails.email, encryptedPassword };
    try {
      const resp = await Api.post(
        "login/",
        { user },
        { withCredentials: true }
      );
      console.log("LOGIN SUCCESS", resp);
      // NOTE: Best practice is to return only the data property to avoid passing non-serializable values like headers
      return resp.data;
    } catch (err) {
      console.log("LOGIN ERROR", err);
      return rejectWithValue(err.response.data);
    }
  }
);

export const logoutUser = createAsyncThunk(
  "users/logoutUser",
  async (arg, { getState }) => {
    const state = getState();
    const email = state.users.currentUser.email;
    try {
      const resp = await Api.post("logout/", { email });
      console.log("LOGOUT SUCCESS", resp);
      return resp.data;
    } catch (err) {
      console.log("LOGOUT ERROR", err);
    }
  }
);

export const updateUserDetails = createAsyncThunk(
  "users/updateUserDetails",
  async (user, { getState, rejectWithValue }) => {
    const formData = convertToFormDataUser(user);

    const headers = {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    };

    try {
      const resp = await Api.patch(
        `users/${user.id}`,
        formData, // This needs to change, see eventsSlice
        { ...headers, withCredentials: true }
      );
      console.log("UPDATE USER SUCCESS", resp);
      return resp.data;
    } catch (err) {
      console.log("UPDATE USER ERROR", err);
      return rejectWithValue(err.response.data); // adds payload and provides error data
    }
  }
);

export const getAllHosts = createAsyncThunk("admin/getAllHosts", async () => {
  try {
    const resp = await Api.get("admin/get_all_hosts", {
      withCredentials: true,
    });
    console.log("GET ALL HOSTS SUCCESS", resp);
    return resp.data;
  } catch (err) {
    console.log("GET ALL HOSTS ERROR", err);
  }
});

export const sendHostConfirmation = createAsyncThunk(
  "admin/sendHostConfirmation",
  async ({ isHostConfirmed, hostUserId }, { getState }) => {
    try {
      const state = getState();
      const currentUser = state.users.currentUser;
      const resp = await Api.patch(
        "admin/handle_host_confirmation",
        { currentUser, email: currentUser.email, isHostConfirmed, hostUserId }, // This needs to change, see eventsSlice
        { withCredentials: true }
      );
      console.log(
        `HOST REQUEST ${isHostConfirmed ? "CONFIRMED" : "REJECTED"}`,
        resp
      );
      return resp.data;
    } catch (err) {
      console.log("HOST REQUEST CONFIRMATION ERROR", err);
    }
  }
);

export const addUser = createAsyncThunk(
  "admin/addUser",
  async (user, { rejectWithValue }) => {
    try {
      const resp = await Api.post(
        "admin/add_user",
        { user },
        { withCredentials: true }
      );
      console.log("ADD NEW USER SUCCESS", resp);
      return resp.data;
    } catch (err) {
      console.log("ADD NEW USER ERROR", err);
      return rejectWithValue(err.response.data); // adds payload and provides error data
    }
  }
);

// is the action type "users/sendContactEmail" needed?
// Should this even be part of the usersSlice/users_controller?
export const sendContactEmail = createAsyncThunk(
  "users/sendContactEmail",
  async (messageDetails, { rejectWithValue }) => {
    try {
      const resp = await Api.post(
        "messages/send_contact_email",
        { messageDetails },
        { withCredentials: true }
      );
      console.log("SEND CONTACT EMAIL SUCCESS", resp);
      return resp.data;
    } catch (err) {
      console.log("SEND CONTACT EMAIL ERROR", err);
      return rejectWithValue(err.response.data); // adds payload and provides error data
    }
  }
);

export const getUserData = async (userId) => {
  try {
    const resp = await Api.get(`users/${userId}`);
    console.log("GET USER DATA SUCCESS", resp);
    return resp.data.user;
  } catch (error) {
    console.log("GET USER DATA ERROR:", error);
    throw error;
  }
};

export const rescheduleEvents = async () => {
  try {
    const resp = Api.post("admin/reschedule_events");
    console.log("RESCHEDULE EVENTS SUCCESS", resp);
    alert("Events rescheduled!");
  } catch (error) {
    console.log("RESCHEDULE EVENTS ERROR", error);
  }
};

// REDUCERS

const initialState = {
  currentUser: {},
  isLoggedIn: false,
};

export const usersSlice = createSlice({
  name: "users",
  initialState,
  reducers: {
    // doSomethingEvent: () => {},
  },
  extraReducers: (builder) => {
    builder
      .addCase(attemptSignup.pending, (state, action) => {
        state.status = "loading";
      })
      .addCase(attemptSignup.fulfilled, (state, action) => {
        state.status = "idle";
      })
      .addCase(attemptSignup.rejected, (state, action) => {
        state.currentUser = {};
        state.status = "idle";
      })
      .addCase(attemptLogin.pending, (state, action) => {
        state.status = "loading";
      })
      .addCase(attemptLogin.fulfilled, (state, action) => {
        state.currentUser = action.payload.user;
        state.isLoggedIn = action.payload.loggedIn;
        state.status = "idle";
      })
      .addCase(attemptLogin.rejected, (state, action) => {
        state.currentUser = {};
        state.status = "idle";
      })
      .addCase(logoutUser.fulfilled, (state) => {
        state.currentUser = {};
        state.isLoggedIn = false;
      })
      .addCase(logoutUser.rejected, () => {
        console.log("LOGOUT FAILED. This should literally never happen.");
      })
      .addCase(updateUserDetails.pending, (state, action) => {
        state.status = "loading";
      })
      .addCase(updateUserDetails.fulfilled, (state, action) => {
        state.currentUser = action.payload.user;
        state.status = "idle";
      })
      .addCase(updateUserDetails.rejected, (state, action) => {
        state.status = "idle";
      });
  },
});

// ACTION CREATORS

// Action creators are generated for each case reducer function
// export const {} = usersSlice.actions;

export default usersSlice.reducer;

// SELECTORS
