import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate, useOutletContext } from "react-router-dom";
import { TextField } from "@mui/material";
import LabelView from "../../atoms/LabelView/LabelView";
import ButtonView from "../../atoms/ButtonView/ButtonView";
import { attemptSignup } from "../../../features/users/usersSlice";
import { validatePassword, validateEmail } from "../../../utils/ProgUtils";
import { muiColors, redColor } from "../../../utils/globalStyles";

// import Checkbox from "@mui/material/Checkbox";
// import FormControlLabel from "@mui/material/FormControlLabel";
// import FormGroup from "@mui/material/FormGroup";

const userDetailFields = [
  { id: "firstName", title: "First Name" },
  { id: "lastName", title: "Last Name" },
  { id: "email", title: "Email" },
  { id: "password", title: "Password" },
  { id: "confirmPassword", title: "Confirm Password" },
  { id: "isHostRequested", title: "Request Host?" },
];

const hostDetailFields = [
  {
    id: "whatsappNumber",
    title: "# Whatsapp (w/ country code)",
  },
  // { id: "bio", title: "Short bio" },
];

export default function SignUpView(props) {
  const { isMobile } = useOutletContext();
  const [userDetails, setUserDetails] = useState({
    firstName: "",
    lastName: "",
    email: "",
    password: "",
    confirmPassword: "",
    isHostRequested: true, // all requests are currently for hosts. users will come in later
    whatsappNumber: "",
    // bio: "",
  });

  const [errorMessage, setErrorMessage] = useState("");
  const [isSending, setIsSending] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const userDetailInput = ({ title, id }) => {
    const isPassword = ["password", "confirmPassword"].includes(id);
    const isEmail = id === "email";
    const inputType = isEmail ? "email" : isPassword ? "password" : undefined;

    const dynamicInput = {
      ...styles.input,
      width: isMobile ? "90%" : 400,
    };

    return (
      <div key={id}>
        <TextField
          label={title}
          variant="outlined"
          required={id !== "lastName"}
          type={inputType}
          sx={dynamicInput}
          value={userDetails[id]}
          // multiline={id === "bio"}
          // minRows={id === "bio" ? 3 : undefined}
          onChange={(e) =>
            setUserDetails({ ...userDetails, [id]: e.target.value })
          }
        />
      </div>
    );
  };

  // const userDetailCheckboxes = () => {
  //   return (
  //     <FormGroup style={styles.filterList}>
  //       <FormControlLabel
  //         key={"requestHost"}
  //         label={"Request host?"}
  //         control={
  //           <Checkbox
  //             checked={userDetails.isHostRequested}
  //             onChange={() =>
  //               setUserDetails({
  //                 ...userDetails,
  //                 isHostRequested: !userDetails.isHostRequested,
  //               })
  //             }
  //           />
  //         }
  //       />
  //     </FormGroup>
  //   );
  // };

  const handleSubmit = async (event) => {
    event.preventDefault();
    const {
      firstName,
      lastName,
      email,
      password,
      confirmPassword,
      isHostRequested,
      whatsappNumber,
      // bio,
    } = userDetails;

    let formattedUserDetails = {
      name: `${firstName} ${lastName}`,
      email: email.toLowerCase(),
      password,
      confirmPassword,
      isHostRequested,
      whatsappNumber,
      // bio,
      // whatsappNumber: isHostRequested ? whatsappNumber : "",
      // bio: isHostRequested ? bio : "",
    };

    const validResp = validatePassword(password, confirmPassword, email);
    const validEmailResp = validateEmail(email);

    const successAlert =
      "Thanks for signing up! We will be in touch shortly to confirm your account.";

    if (validResp.isValid && validEmailResp.isValid) {
      setIsSending(true);
      try {
        const resp = await dispatch(
          attemptSignup(formattedUserDetails)
        ).unwrap();
        setErrorMessage("");
        if (resp.status === "created") {
          alert(successAlert);
          navigate("/");
        }
      } catch (err) {
        console.log("SIGNUP ERROR", err);
        setErrorMessage(err.errors[0]);
      }
      setIsSending(false);
    } else {
      setErrorMessage(validResp.errorMessage || validEmailResp.errorMessage);
    }
  };

  // if any field is falsey, form is incomplete
  const isFormComplete = !Object.values(userDetails).some((detail) => !detail);

  const hostExplanation = `Event organziers and hosts can freely post and update their events on Latin Dance Bali at any time. `;
  const secondHostExplanation = `If you want to post an event, please fill out the form below. We will let you know when your account is ready, usually within 24 hours.`;
  const thirdHostExplanation = `Once your account is ready, you can log in and post as many events as you wish. Happy dancing! 💃🏼🕺🏽 `;
  return (
    <div style={styles.container}>
      <LabelView text="Create Host Account" type="pageHeader" />
      <LabelView text={hostExplanation} style={{ margin: "8px 0px" }} />
      <LabelView text={secondHostExplanation} style={{ margin: "8px 0px" }} />
      <LabelView text={thirdHostExplanation} style={{ margin: "8px 0px" }} />
      <div style={styles.inputsContainer}>
        {userDetailFields
          .filter((field) => field.id !== "isHostRequested")
          .map((field) => userDetailInput(field))}
        {/* {userDetailCheckboxes()} */}
        {userDetails.isHostRequested &&
          hostDetailFields.map((field) => userDetailInput(field))}
      </div>
      {!!errorMessage && (
        <LabelView text={errorMessage} style={{ color: redColor }} />
      )}
      <div style={{ display: "flex", marginTop: 12 }}>
        <ButtonView
          text="Create"
          color={muiColors.success}
          variant="contained"
          disabled={!isFormComplete || isSending}
          onClick={handleSubmit}
        />
        <ButtonView
          text="Back"
          sx={{ ml: 2 }}
          color={muiColors.black}
          variant="contained"
          to="/"
          disabled={isSending}
        />
      </div>
      {isSending && <div>Sending...</div>}
    </div>
  );
}

const styles = {
  container: {
    padding: 16,
  },
  inputsContainer: {
    display: "flex",
    flexDirection: "column",
  },
  input: {
    m: 1,
  },
};
