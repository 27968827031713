import React from "react";
import { useOutletContext } from "react-router-dom";
import { selectMajorEventIds } from "../../../features/events/eventsSlice";
import { useSelector } from "react-redux";
import EventItem from "../../../features/events/EventItem";
import LabelView from "../../atoms/LabelView/LabelView";

export default function SpecialView(props) {
  const { isMobile } = useOutletContext();
  const majorEventIds = useSelector(selectMajorEventIds);

  const noEventsView = (
    <div style={{ width: isMobile ? undefined : 584, marginTop: 12 }}>
      <LabelView text="No big events coming up. Hopefully soon!" />
    </div>
  );

  // Make this by month maybe
  const EventsContainer = () => {
    const mappedEvents = (
      <div style={styles.eventsContainer}>
        {majorEventIds.map((id) => (
          <EventItem key={id} id={id} isMobile={isMobile} isFeed />
        ))}
      </div>
    );

    return (
      <div style={{ minWidth: isMobile ? undefined : 700 }}>
        {majorEventIds.length ? mappedEvents : noEventsView}
      </div>
    );
  };

  return (
    <div style={styles.container}>
      <div style={styles.feedContainer}>
        <LabelView
          text="Conferences, Workshops, and Special Events,"
          type={"smalltitle"}
          style={{ marginBottom: 8 }}
        />
        <EventsContainer />
      </div>
    </div>
  );
}

const styles = {
  container: {
    display: "flex",
    height: "100%",
  },
  feedContainer: {
    width: "100%",
    padding: 16,
  },
  eventsContainer: {
    display: "flex",
    flexWrap: "wrap",
    margin: "0px 10px",
  },
};
