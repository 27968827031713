import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import "./index.css";

// Routing
import { Navigate, Route, BrowserRouter, Routes } from "react-router-dom";
import ProtectedRoutes from "./utils/ProtectedRoutes";
import ProtectedAdminRoutes from "./utils/ProtectedAdminRoutes";

// Pages
import Root from "./routes/root";
import CreateEventView from "./components/pages/CreateEventView/CreateEventView";
import EventFeedView from "./components/pages/EventFeedView/EventFeedView";
import EditEventView from "./components/pages/EditEventView/EditEventView";
import SignUpView from "./components/pages/SignUpView/SignUpView";
import LoginView from "./components/pages/LoginView/LoginView";
import SettingsView from "./components/pages/SettingsView/SettingsView";
import AdminView from "./components/pages/AdminView/AdminView";
import MyEventsView from "./components/pages/MyEventsView/MyEventsView";
import SingleEventView from "./components/pages/SingleEventView/SingleEventView";
import TermsView from "./components/pages/TermsView/TermsView";
import AdminHostProfileView from "./components/pages/AdminHostProfileView/AdminHostProfileView";
import ContactUsView from "./components/pages/ContactUsView/ContactUsView";
import HostsOverviewView from "./components/pages/HostsOverviewView/HostsOverviewView";
import HostProfilePageView from "./components/pages/HostProfilePageView/HostProfilePageView";
import SpecialEventsView from "./components/pages/SpecialEventsView/SpecialEventsView";
import ForgotPasswordView from "./components/pages/ForgotPasswordView/ForgotPasswordView";
import ResetPasswordView from "./components/pages/ResetPasswordView/ResetPasswordView";

// Redux
import { store, persistor } from "./app/store";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";

// Date Handling
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";

// MUI
import { ThemeProvider } from "@mui/material/styles";
import { theme } from "./utils/Theme";

// remove localforage match-sorter sort-by later

//   // I can potentially use <BrowserRouter /> if I don't need the data APIs
//   // React.StrictMode causes useEffect (and other lifecycle methods) to fire twice

const ScrollToTop = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
};

function App() {
  // <Route path="/" element={<Root />}> For some reason, this has the footer and header in it. This must be the <Outlet/>

  // Will have to re-add error boundary
  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        {/* <React.StrictMode> */}
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <ThemeProvider theme={theme}>
            <BrowserRouter>
              <ScrollToTop />
              <Routes>
                <Route path="/" element={<Root />}>
                  <Route element={<ProtectedRoutes />}>
                    <Route path="settings" element={<SettingsView />} />
                    <Route
                      path="createEvent"
                      exact
                      element={<CreateEventView />}
                    />
                    <Route
                      path="createEvent/:id"
                      exact
                      element={<CreateEventView />}
                    />
                    <Route path="editEvent/:id" element={<EditEventView />} />
                    <Route path="myEvents" element={<MyEventsView />} />
                    <Route path="contact" exact element={<ContactUsView />} />
                  </Route>

                  <Route element={<ProtectedAdminRoutes />}>
                    <Route path="admin" element={<AdminView />} />
                    <Route
                      exact
                      path="admin/hostProfile/:id"
                      element={<AdminHostProfileView />}
                    />
                  </Route>

                  <Route path="/" element={<EventFeedView />} />
                  <Route path="events/:id" element={<SingleEventView />} />
                  <Route path="login" element={<LoginView />} />
                  <Route path="signup" element={<SignUpView />} />
                  <Route
                    path="forgotPassword"
                    element={<ForgotPasswordView />}
                    exact
                  />
                  <Route
                    path="resetPassword/:resetPasswordToken"
                    element={<ResetPasswordView />}
                    exact
                  />
                  <Route
                    path="hostsOverview"
                    exact
                    element={<HostsOverviewView />}
                  />
                  <Route
                    path="hostsOverview/:id"
                    exact
                    element={<HostProfilePageView />}
                  />
                  <Route
                    // this could also be a toggle button / filter in the event feed
                    path="specialEvents"
                    exact
                    element={<SpecialEventsView />}
                  />
                  <Route
                    path="terms"
                    exact
                    element={<TermsView textBlock="terms" />}
                  />
                  <Route
                    path="privacy"
                    exact
                    element={<TermsView textBlock="privacy" />}
                  />
                  <Route
                    path="about"
                    exact
                    element={<TermsView textBlock="about" />}
                  />
                  {/* <Route path="contact" exact element={<ContactUsView />} /> */}

                  <Route path="*" element={<Navigate to="/" replace />} />
                </Route>
              </Routes>
            </BrowserRouter>
          </ThemeProvider>
        </LocalizationProvider>
        {/* </React.StrictMode> */}
      </PersistGate>
    </Provider>
  );
}

export default App;

// 08/2023- I replaced <RouterProvider> with <BrowserRouter> so that I could use protected routes
// No longer using this inside <LocalizationProvider>: <RouterProvider router={router} />
// Couldn't find a good way to add proteted routes into createBrowserRouter()
// If I want to try again, the browser router object is as below

// import ErrorPage from "./error-page";
// import Contact from "./routes/contact";
// import Root, {
//   loader as rootLoader,
//   action as rootAction,
// } from "./routes/root";

// const router = createBrowserRouter([
//   {
//     path: "/",
//     element: <Root />,
//     errorElement: <ErrorPage />,
//     loader: rootLoader,
//     action: rootAction,
//     children: [
//       {
//         path: "contacts/:contactId",
//         element: <Contact />,
//       },
//       {
//         path: "/",
//         element: (
//           <div style={{ display: "flex" }}>
//             <FiltersView />
//             <EventFeedView />
//           </div>
//         ),
//       },
//       {
//         path: "createEvent",
//         element: (
//           <ProtectedRoute path="settings" element={<CreateEventView />} />
//         ),
//       },
//       {
//         path: "editEvent/:id",
//         element: <ProtectedRoute path="settings" element={<EditEventView />} />,
//       },
//       {
//         path: "signup",
//         element: <SignUpView />,
//       },
//       {
//         path: "login",
//         element: <LoginView />,
//       },
//       {
//         path: "settings",
//         element: <ProtectedRoute path="settings" element={<SettingsView />} />,
//       },
//       {
//         path: "admin",
//         element: <ProtectedRoute path="admin" element={<AdminView />} />,
//       },
//       {
//         path: "myEvents",
//         element: <ProtectedRoute path="myEvents" element={<MyEventsView />} />,
//       },
//       {
//         path: "*",
//         element: <Navigate to="/" replace />,
//       },
//     ],
//   },
// ]);
