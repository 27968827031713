import { TextField } from "@mui/material";
import React, { useState, useEffect } from "react";
import ButtonView from "../../atoms/ButtonView/ButtonView";
import { useDispatch } from "react-redux";
import { sendPasswordResetEmail } from "../../../features/users/usersSlice";
import { useNavigate } from "react-router-dom";
import LabelView from "../../atoms/LabelView/LabelView";
import { muiColors, redColor } from "../../../utils/globalStyles";

export default function LoginView(props) {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [email, setEmail] = useState("");

  const [errorMessage, setErrorMessage] = useState("");

  const getPasswordResetEmail = async () => {
    try {
      const resp = await dispatch(sendPasswordResetEmail(email)).unwrap();
      alert("Please check your email for the reset password link.");
      navigate("/");
    } catch (err) {
      setErrorMessage(err.message);
    }
  };

  return (
    <div style={styles.container}>
      <LabelView
        text="Forgot Password?"
        type="pageHeader"
        style={{ marginBottom: 8 }}
      />
      <LabelView
        text="Enter your email address and we'll send you an email to reset your password."
        style={{ marginBottom: 8 }}
      />
      <div style={styles.inputsContainer}>
        <TextField
          label="Email Address"
          variant="outlined"
          sx={styles.input}
          value={email}
          autoFocus={true}
          onChange={(e) => setEmail(e.target.value)}
        />

        {!!errorMessage && (
          <LabelView
            text={errorMessage}
            style={{ color: redColor, marginBottom: 8 }}
          />
        )}
      </div>

      <div style={{ display: "flex", marginBottom: 16 }}>
        <ButtonView
          text="Reset Password Via Email"
          color={muiColors.success}
          variant="contained"
          onClick={getPasswordResetEmail}
        />
        <ButtonView
          text="Back"
          sx={{ ml: 2 }}
          color={muiColors.black}
          variant="contained"
          to="/"
        />
      </div>
    </div>
  );
}

const styles = {
  container: {
    padding: 16,
  },
  inputsContainer: {
    display: "flex",
    flexDirection: "column",
    flexWrap: "wrap",
    height: "80%",
  },
  input: {
    m: 1,
    width: "300px",
  },
};
