import { useSelector } from "react-redux";
import { selectEventById, selectEventsOfUser } from "./eventsSlice";
import { useNavigate } from "react-router-dom";
import ButtonView from "../../components/atoms/ButtonView/ButtonView";
import LabelView from "../../components/atoms/LabelView/LabelView";
import SingleEventMainView from "../../components/organisms/SingleEventMainView/SingleEventMainView";
import { formatPrice } from "../../utils/ProgUtils";
import placeholderImage from "../../resources/images/event_placeholder_612x612.jpg";
import dayjs from "dayjs";
import { muiColors, paletteRed } from "../../utils/globalStyles";
import { selectIsAdmin } from "../../utils/UserPermissions";

const EventItem = (props) => {
  const { id, showEditButton, showPrefillButton, showDate, isMobile, isFeed } =
    props;
  const navigate = useNavigate();

  const event = useSelector((state) => selectEventById(state, id));
  const eventsOfUser = useSelector(selectEventsOfUser);
  const eventIdsOfUser = eventsOfUser.map((event) => event.id);
  const isEventHost = eventIdsOfUser.includes(id);
  const isAdmin = useSelector(selectIsAdmin); // I took this out for some reason, make sure its ok to put back in

  // Dev uses activeStorage and will have a localhost redirect to the image hosted in activeStorage
  // Prod uses Cloudinary and will have a localhost redirect to the image hosted on cloudinary
  const eventImageUrl = event.cloudinaryEventImageUrl || event.eventImageUrl;

  const editButton = (
    <ButtonView
      variant="contained"
      color={muiColors.black}
      text="Edit"
      sx={{ height: "26px", mr: 1 }}
      to={`/editEvent/${id}`}
    />
  );

  // const prefillDetails = (
  //   <ButtonView
  //     variant="contained"
  //     color={muiColors.black}
  //     text="Pre-fill"
  //     sx={{ height: "26px", mr: 1 }}
  //     to={`/createEvent/${id}`}
  //   />
  // );

  const withHostsString = !!event.hosts ? ` hosted by ${event.hosts}` : "";
  const cost = formatPrice(event.coverCharge);
  const musicByString = !!event.musicProvider
    ? ` Music by ${event.musicProvider}.`
    : "";

  const eventDescription = (
    <span>
      <b>{event.name}</b> at <b>{event.venue}</b>
      {withHostsString}. {musicByString} {cost}.
    </span>
  );

  const eventTime = (
    <>
      {dayjs(event.startTime).format("HH:mm")}-
      {dayjs(event.endTime).format("HH:mm")}
    </>
  );

  const eventDate = dayjs(event.startTime).format("MM/D");
  // for mulit-day events only
  const dateRange = `${dayjs(event.startTime).format("MMM DD")} - ${dayjs(
    event.endTime
  ).format("MMM DD")}`;

  // const eventLocationLink = (
  //   <div style={{ marginRight: 12 }}>
  //     <LinkView text={"Location"} href={event.locationLink} target="_blank" />
  //   </div>
  // );

  // allow admins (and later hosts) to access these buttons
  // slightly unfortunately consequence of using these event views in both
  // the feed and admin/host views
  // probably better to do this with a e.stopEventPropagation() or something
  const disableEventNav = showEditButton || showPrefillButton;

  const shortDescription = () => {
    const dynamicShortEventContainer = {
      ...styles.shortEventContainer,
      border: event.isMajor ? `3px solid ${paletteRed}` : "",
    };

    return (
      <div
        onClick={disableEventNav ? undefined : () => navigate(`/events/${id}`)}
        style={dynamicShortEventContainer}
      >
        <div style={styles.shortDetailsText}>
          <div style={{ display: "flex" }}>
            {/* showDate is for hosts only  */}
            {showDate && (
              <LabelView text={eventDate} style={{ marginRight: 6 }} />
            )}
            <LabelView
              text={event.isMajor ? dateRange : eventTime}
              style={{ marginRight: isMobile ? 6 : 12 }}
              isBold
            />
          </div>
          <div style={styles.shortDetailsContainer}>
            <LabelView text={eventDescription} style={{ marginBottom: 4 }} />
            {/* {!isMobile && eventLocationLink} */}
            {event.status === "cancelled" && (
              <div style={styles.cancelledLabel}>
                <LabelView text="Cancelled" type="body" isBold />
                {/* <LabelView text="See you next week!" type="body" /> */}
              </div>
            )}

            <div style={{ flex: 1 }}>
              {(isEventHost || isAdmin) && showEditButton && editButton}
              {/* taking out pre-fill for now, just allow them to edit instead */}
              {/* {showPrefillButton && prefillDetails} */}
            </div>
          </div>
        </div>
        <img
          alt={"Event Poster"}
          src={eventImageUrl || placeholderImage}
          height={120}
          width={72}
          style={{
            objectFit: "cover",
            filter: !!event.eventImageUrl ? undefined : "opacity(0.1)",
            marginLeft: "auto",
          }}
        />
      </div>
    );
  };

  // The ternary conditional is pretty shit. showDate basically means it in a host view
  return isMobile || showDate ? (
    shortDescription()
  ) : (
    <SingleEventMainView event={event} isFeed={isFeed} />
  );
};

const styles = {
  container: {
    display: "flex",
    flexDirection: "column",
    margin: "0px 24px 24px 0px",
  },
  shortEventContainer: {
    display: "flex",
    width: "100%",
    borderRadius: 8,
    padding: 12,
    margin: "6px 0px",
    boxShadow: "0px 4px 8px 4px rgba(0,0,0,0.3)",
  },
  shortDetailsContainer: {
    display: "flex",
    flexDirection: "column",
    maxWidth: 480,
    marginTop: 8,
    position: "relative",
  },
  shortDetailsText: {
    display: "flex",
    flexDirection: "column",
    marginRight: 8,
  },
  cancelledLabel: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    backgroundColor: paletteRed,
    color: "white",
    padding: "5px 10px",
    width: "100%",
    borderRadius: "10px", // Optional for styling
    // zIndex: 1, // Ensure it appears over the image
  },
  // eventPlaceholder: {
  //   height: 120,
  //   width: 80,
  //   background: "goldenrod",
  //   display: "flex",
  //   alignItems: "center",
  //   textAlign: "center",
  // },
  // boxSx: {
  //   width: 200,
  //   height: 200,
  //   backgroundColor: "primary.dark",
  //   "&:hover": {
  //     backgroundColor: "primary.main",
  //     opacity: [0.9, 0.8, 0.7],
  //   },
  // },
};

export default EventItem;
